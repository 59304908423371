.collapse-invoice .ant-collapse-content>.ant-collapse-content-box{
    display: flex;
    justify-content: center;
}

/* 
.collapse-invoice .ant-collapse-content {
    transition: max-height 0.2s ease;
  }
  
  .collapse-invoice.panel-open .ant-collapse-content-box {
    max-height: 400px; 
    overflow-y: auto;
  } */