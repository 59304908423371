.fixed-footer .ant-modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 10;
    background: #fff;
  }

  .ant-modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 10;
    background: #fff;
  }

  .ant-modal-header {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .ant-modal-content {
    overflow-y: auto;
    position: relative;
  }

  .ant-modal {
      box-sizing: border-box;
      color: rgba(0, 0, 0, .85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: "tnum";
      pointer-events: none;
      overflow: hidden;
      top:50px;
      bottom:20px;
      top:50px;
      bottom:20px;
      flex-direction: column;
      display: flex;
      position: fixed;
      /* width: auto; */
      /* max-width: calc(100vw - 32px); */
      margin: 0 auto;
      padding: 0 0 24px;
  }

  .ant-modal-wrap {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      outline: 0;
      z-index: 1000;
      display: flex;                
      justify-content: center;      
      align-items: center;        
  }

  .totals-section {
    margin-top: 50px;
    margin-right: 20px;
    font-size: 1.0em;
    text-align: right;
  }

  .ant-card-bordered .ant-card-cover{
    padding-left: 15px;
    padding-right: 15px;
  }

  .ant-layout-content {
    position: relative;
    overflow-y: auto;
    margin-top: 70px !important;
    margin-bottom: 10px !important;
    padding-top: 30px !important;
   }

    .actions-column {
        white-space: nowrap;
    }

   /* .card-size {
        
        display: flex;
        justify-content: center;
        align-items: center;
    } */

    .ant-table-wrapper {
        clear: both;
        max-width: 100%; 
        overflow-x: auto; 
    }
    
    .ant-table {
        min-width: 600px;
    }
    
    @media (max-width: 768px) {
        .ant-table-wrapper {
            padding: 0 10px;
        }
    
        .ant-table th, .ant-table td {
            font-size: 12px;
            padding: 8px;
        }
    
        .ant-table-column-title {
            white-space: nowrap;
        }
    }
    /* @media (min-width: 992px) {
        .ant-col-lg-6 {
            display: block;
            flex: 1 1 auto;
            max-width: 100%;
            padding: 10px;
            height: auto;
        }
    }

    @media (min-width: 768px) {
        .ant-col-md-12 {
            display: block;
            flex: 0 0 auto;
            max-width: 50%;
            padding: 10px;
            height: auto;
        }
    }

    @media (min-width: 576px) {
        .ant-col-sm-6 {
            display: block;
            flex: 0 0 auto;
            max-width: 50%;
            padding: 10px;
            height: auto;
        }
    } */
    @media (max-width: 576px) {
        .card-size{
            width: 100%;
        }
        .ant-card{
            width: 100%;
            overflow: hidden;
            
        }
        .ant-card-body{
            /* background-color: rgb(250, 219, 215); */
            /* flex-grow: 1 0 auto; */
            max-width: 100%;
            /* text-overflow: ellipsis;
            max-width: 180px; */
            /* width: clamp(130px,2.5em, 300px); */
            /* width: 150px; */
        }
        .ant-card-meta-title{
            font-size: 1.2em !important;
        }
        .ant-card-meta-description > span{
            font-size: 1.2em!important;
        }
        .ant-modal{
            max-width:70vw;
            max-height: 90vh;
        }
        /* .ant-card-meta{
            width:70%;
        } */
        .site-layout-background .ant-row {
            display: grid;
            grid-template-columns: repeat(1, 1fr); /* Creates 6 equal-width columns */
            gap: 0.3rem; /* Adds spacing between items */
          }
        .ant-col {
            /* background-color: aliceblue; */
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            display:block;
            /* flex: 1 0 100%; */
            /* max-width: 100%;  */
            max-width:70vw; 
        }
      }
      @media (min-width: 577px) and (max-width: 768px) {
        .ant-card{
            width: 100%;
            overflow: hidden;
        }
        .ant-card-body{
            /* background-color: antiquewhite; */
            /* flex-grow: 1 0 auto; */
            max-width: 100%;
            text-overflow: ellipsis;
            width: clamp(160px,2.5em, 300px);
            /* width: 150px; */
        }
        .ant-card-meta-title{
            font-size: 1.1em !important;
        }
        .ant-card-meta-description > span{
            font-size: 1.1em!important;
        }
        .ant-modal{
            max-width:70vw;
            max-height: 90vh;
        }
        .ant-card-meta{
            max-width:70vw;
        }
        .site-layout-background .ant-row {
            display: grid;
            grid-template-columns: repeat(2, 1fr); /* Creates 6 equal-width columns */
            gap: 0.3rem; /* Adds spacing between items */
          }
        .ant-col {
            /* background-color: rgb(189, 42, 42); */
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            display:block;
            /* flex: 1 0 25%; */
            /* max-width: 25%;  */
            max-width:70vw; 
        }
      }

      @media (min-width: 769px) and (max-width: 992px) {
        .ant-card{
            width: 100%;
            overflow: hidden;
        }
        .ant-card-body{
            /* background-color: rgb(133, 156, 68); */
            /* flex-grow: 1 0 auto; */
            /* max-width: 100%; */
            /* min-width: 170px; */
            max-width: 150px;
            text-overflow: ellipsis;
            /* width: clamp(160px,2.5em, 300px); */
            /* width: 150px; */
        }
        .ant-card-meta-title{
            font-size: 1.05em !important;
        }
        .ant-card-meta-description > span{
            font-size: 1.1em!important;
        }
        .ant-card-meta{
            max-width:70vw;
        }
        .ant-modal{
            max-width:70vw;
            max-height: 90vh;
        }
        .site-layout-background .ant-row {
            display: grid;
            grid-template-columns: repeat(3, 1fr); /* Creates 6 equal-width columns */
            gap: 0.3rem;/* Adds spacing between items */
          }
        .ant-col {
            /* background-color: greenyellow; */
            
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            display:block;
            /* flex: 1 0 50%; */
            /* max-width: 50%;  */
            max-width:70vw; 
        }
      }
      @media (min-width: 993px) and (max-width: 1200px) {
        .ant-card{
            width: 100%;
            overflow: hidden;
        }
        .ant-card-body{
            /* background-color: rgb(215, 224, 250); */
            /* flex-grow: 1 0 auto; */
            max-width: 160px;
            /* max-width: 160px; */
            text-overflow: ellipsis;
            /* width: clamp(150px,2.5em, 300px); */
            /* width: 150px; */
        }
        .ant-card-meta-title{
            font-size: 1.15em !important;
        }
        .ant-card-meta-description > span{
            font-size: 1.15em!important;
        }
        .ant-modal{
            max-width:70vw;
            max-height: 90vh;
        }
        .ant-card-meta{
            max-width:70vw;
        }
        .site-layout-background .ant-row {
            display: grid;
            grid-template-columns: repeat(4, 1fr); /* Creates 6 equal-width columns */
            gap: 0.3rem; /* Adds spacing between items */
          }
        .ant-col {
            
            /* background-color: rgb(202, 168, 219); */
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            display:block;
            /* flex: 1 0 25%; */
            max-width:70vw; 
        }
      }
      @media (min-width: 1201px) {
        .ant-card{
            width: 100%;
            overflow: hidden;
        }
        .ant-card-body{
            /* background-color: rgb(216, 250, 215); */
            /* flex-grow: 1 0 auto; */
            /* max-width: 100%; */
            text-overflow: ellipsis;
            min-width: 210px;
            max-width: 300px;
            /* width: clamp(210px,2.5em, 300px); */
            /* width: 150px; */
        }
        .ant-card-meta-title{
            font-size: 1.2em !important;
        }
        .ant-card-meta-description > span{
            font-size: 1.2em!important;
        }
        .ant-modal{
            max-width:70vw;
            max-height: 90vh;
        }
        .site-layout-background .ant-row {
            display: grid;
            grid-template-columns: repeat(4, 1fr); /* Creates 6 equal-width columns */
            gap: 0.3rem; /* Adds spacing between items */
          }
        .ant-col {
            /* background-color: rgb(219, 84, 179); */
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            display:block;
            /* justify-content: center; */
            /* flex: 1 0 25%; */
          max-width:100%; 
          /* height: 30vh; */
        }
      }

      @media (min-width: 1500px) {
      
        .ant-modal{
            max-width:70vw;
            max-height: 90vh;
        }
        .site-layout-background .ant-row {
            display: grid;
            grid-template-columns: repeat(5, 1fr); /* Creates 6 equal-width columns */
            gap: 0.3rem; /* Adds spacing between items */
          }
        .ant-col {
            /* background-color: rgb(219, 84, 179); */
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            display:block;
            /* justify-content: center; */
            /* flex: 1 0 25%; */
          max-width:100%; 
          /* height: 30vh; */
        }
      }

      @media (min-width: 1800px) {
      
        .ant-modal{
            max-width:70vw;
            max-height: 90vh;
        }
        .site-layout-background .ant-row {
            display: grid;
            grid-template-columns: repeat(6, 1fr); /* Creates 6 equal-width columns */
            gap: 0.3rem; /* Adds spacing between items */
          }
        .ant-col {
            /* background-color: rgb(219, 84, 179); */
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            display:block;
            /* justify-content: center; */
            /* flex: 1 0 25%; */
          max-width:100%; 
          /* height: 30vh; */
        }
      }

      @media (min-width: 2400px) {
      
        .ant-modal{
            max-width:70vw;
            max-height: 90vh;
        }
        .site-layout-background .ant-row {
            display: grid;
            grid-template-columns: repeat(10, 1fr); /* Creates 6 equal-width columns */
            gap: 0.3rem; /* Adds spacing between items */
          }
        .ant-col {
            /* background-color: rgb(219, 84, 179); */
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            display:block;
            /* justify-content: center; */
            /* flex: 1 0 25%; */
          max-width:100%; 
          /* height: 30vh; */
        }
      }

      @media (min-width: 3200px) {
      
        .ant-modal{
            max-width:70vw;
            max-height: 90vh;
        }
        .site-layout-background .ant-row {
            display: grid;
            grid-template-columns: repeat(12, 1fr); /* Creates 6 equal-width columns */
            gap: 0.3rem; /* Adds spacing between items */
          }
        .ant-col {
            /* background-color: rgb(219, 84, 179); */
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            display:block;
            /* justify-content: center; */
            /* flex: 1 0 25%; */
          max-width:100%; 
          /* height: 30vh; */
        }
      }

      @media (min-width: 4800px) {
      
        .ant-modal{
            max-width:70vw;
            max-height: 90vh;
        }
        .site-layout-background .ant-row {
            display: grid;
            grid-template-columns: repeat(16, 1fr); /* Creates 6 equal-width columns */
            gap: 0.3rem; /* Adds spacing between items */
          }
        .ant-col {
            /* background-color: rgb(219, 84, 179); */
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            display:block;
            /* justify-content: center; */
            /* flex: 1 0 25%; */
          max-width:100%; 
          /* height: 30vh; */
        }
      }
       .ant-card-meta-title{
        text-overflow: ellipsis;
       }           

       .ant-card-meta-description{
        text-overflow: ellipsis;
       }
      
       .badgeStyle{
        display: flex;
        width: auto;
        flex-grow: 1;
        justify-content: end;
        padding-right: 10px;
       }

       .ant-collapse>.ant-collapse-item>.ant-collapse-header{
        align-items: center;
       }

       .ant-badge-status-dot {
            width: 8px;
            height: 8px;
        }

        .BadgeCountDesign{
            display: inline-flex;
            flex-grow: 1;
            justify-content: flex-end;
            align-items: center;
        }

        .BadgePerCountDesign{
            padding-left: 7px;
            min-width: 40px;
        }

        .BadgePerCountOutsideDesign{
            padding-left: 7px;
            min-width: 0;
            border: none; 
        }

        .BadgePerCountOutsideDesign .ant-badge-count {
            border: none !important;
            box-shadow: none !important;
        }

        .ant-divider-horizontal {
            margin: 12px 0;
            margin-top: 13px;
        }   

        .fixed-footer .ant-modal-title {
            margin: 0;
            margin-top: 5px;
        }

        .fixed-footer .ant-modal-header {
            padding: 0;
            padding-top: 10px;
            border-bottom: 0 solid #f0f0f0;
        }

        .fixed-footer .ant-modal-body {
            padding: 24px;
            padding-top: 0;
        }

        .showButtonOnTop{
            padding: 0px 24px;
            padding-bottom: 17px;
            padding-top: 14px;
            flex-wrap: wrap;
        }

        .tablePadding{
            padding-left: 24px;
        }
    
        .ant-modal-body {
            padding: 24px;
            padding-top: 10px;
            padding-bottom: 10px;
          }
          
          .ant-modal-header {
            padding: 16px 24px;
            border-bottom: 1px solid #e8e8e8;
          }

          .no-spinner .ant-input-number-handler-wrap {
            display: none; /* Hides the spinner */
          }

          .custom-label-remark{
            margin-left: 10px;
          }

          .ant-input[disabled] {
                background-color: #f1f1f1;
                color: #958e8e;
            }
          
          .ant-input-number {
            width: 100%;
            height: 32px;
            border: 1px solid #d9d9d9;
            font-size: 14px;
            border-radius: 4px;
          }
          
    
    