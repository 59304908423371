@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");

body {
  font-family: "Sora", sans-serif;
}

/***************register page *******************/
.register{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  
}

.register input{
  width: 400px !important;
  
}

.register-form{
  background-color: rgba(98,197,251,0.463);
  padding: 20px;
  box-shadow: rgba(50,50,93,0.25) 0px 50px 100px -20px,
              rgba(0,0,0,0.3) 0px 30px 60px -30px,
              rgba(10,37,64,0.35) 0px -2px 6px 0px inset;
}

.register-form h1{
  text-align: center;
  background-color: rgb(4, 110, 203);
  color: white;
  padding: 10px;
}

.register-form h3{
  text-align: center;
  color: rgb(4,4,113);
  padding: 10px;
  
}