.upload-button {
    position: absolute;
    top: 0;
    left: 0;
    /* transform: translate(-50%, -50%); */
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    color: #1890ff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
  }
  .upload-container {
    padding: 10px; /* Adjust padding as needed */
  }
  
  .upload-container .ant-upload-list-item {
    padding: 8px; /* Space between items */
  }

  .ant-progress-outer{
    width: 90%;
  }
  