/* Receipt Styles */
.receipt-container {
    width: 100%; /* Full width for thermal printer */
    margin: 0; /* No margin */
    padding: 0 ; /* Minimal padding */
    border: none; /* No border */
    background-color: #fff; /* White background */
}

.receipt-header {
    text-align: center;
    margin-bottom: 5px; /* Reduced margin */
}

.receipt-header h1 {
    margin: 0; /* No margin */
    font-size: 16px; /* Adjusted font size */
    color: #000; /* Black text */
}

.receipt-details {
    font-size: 10px; /* Smaller font size */
    color: #000; /* Black text */
    margin-bottom: 5px; /* Reduced margin */
}

.receipt-details p {
    margin: 0; /* No margin */
}

.receipt-table {
    width: 100%; /* Full width */
    border-collapse: collapse; /* Remove gaps */
    margin-bottom: 5px; /* Reduced margin */
}

.receipt-table th,
.receipt-table td {
    padding: 3px; /* Minimal padding */
    text-align: left;
    font-size: 10px; /* Smaller font size for printing */
    color: #000; /* Black text */
}

.receipt-table th {
    font-weight: bold; /* Bold header text */
}

.receipt-total,
.receipt-subtotal {
    text-align: right; /* Align totals to the right */
    font-size: 10px; /* Smaller font size */
    font-weight: bold; /* Bold for emphasis */
    color: #000; /* Black text */
}

.receipt-footer {
    text-align: center;
    margin-top: 5px; /* Reduced margin */
    font-size: 8px; /* Smaller font size for footer */
    color: #000; /* Black text */
}

.align-right {
    float: right;
    margin-top: 20px;
}

@media print {
    .print-modal .close-button {
        display: none !important;
    }

    .print-modal .ant-modal-close {
        display: none !important;
    }

    .print-modal .ant-modal-header,
    .print-modal .ant-modal-footer {
        display: none !important;
    }
}
