#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }
  
  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    background: #fff;
    box-shadow: 0 0 3px #ccc;
    border-radius: 5px;
    margin: 10px;
  }
  
  .ant-layout-has-sider {
    padding: 5px !important;
  }
  
  .ant-layout.ant-layout-has-sider {
    height: 100vh;
  }
  
  .ant-layout-sider {
    border-radius: 10px !important;
    padding-top: 10px !important;
  }
  
  .anticon svg {
    height: 20px;
    width: 20px;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    /* margin-left: 10px; */
  }

  .ant-menu-dark .ant-menu-inline.ant-menu-sub{
    background-color: inherit;
  }

  .ant-btn-round.ant-btn-lg {
    padding: 7px;
  }
  .inner{
    width: 300px;
    height: 200px;
}
  .qr-row{
    width: 500px;
    height: 100px;
    margin-left: 30px;
}
.qrimg-src{
    float: left;
    width: 250px;
    height: 80px;
}
.qrtxt{
    float: left;
    width: 250px;
    height: 100px;
    margin-top: 35px;
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 30px;
  text-align: center;
}
