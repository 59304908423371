/* General container styles */
.ant-form {
    /* margin-left: 150px;
    margin-right: 150px; */
    min-height: 90%;
    border-radius: 8px;
    padding: 20px 30px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* Form label styles */
.ant-form-item-label label {
    font-size: 1rem;
    font-weight: 600;
    color: #555;
    margin-bottom: 5px;
}

/* Input field styles */
.ant-input,
.ant-picker,
.ant-upload-list-picture .ant-upload {
    border-radius: 5px;
    font-size: 1rem;
    padding: 8px 12px;
    border: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    transition: border-color 0.3s ease;
}

.input_1 {
    min-width: 200%;
    margin-bottom: 5px !important;
    border-radius: 5px !important;

}

.ant-input:focus,
.ant-picker-focused,
.ant-upload-list-picture .ant-upload:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 8px rgba(24, 144, 255, 0.2);
}

span .anticon svg{
    margin-top: -2px !important;
}

/* Button styles */
.ant-btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
    /* font-size: 1rem;
    padding: 10px 16px; */
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.ant-btn-primary:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
}

.bottom-right-container button{
    left: 660px;
    height: 43px;
}

/* Form alignment */
.ant-form-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
}

.ant-form-item-label {
    align-self: flex-start;
}

.ant-upload {
    display: flex;
    align-items: center;
    gap: 10px;
}

.upload-logo img {
    width: 150px;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
}

/* Submit button alignment */
.d-flex {
    display: flex;
    justify-content: flex-end;
}