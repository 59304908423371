.custom-cart-icon svg {
    font-size: 70px !important;
    /* Add !important to force override */
    fill: white !important;
    /* Ensure color is applied */
    margin-top: 0px;
    min-width: 40px;
    min-height: 40px;
}

/* Target the value inside the Statistic component */
.ant-statistic-content-value {
    font-weight: bold;
    /* Make the value bold */
    font-size: 22px;
    /* Keep or adjust the font size */
    color: #000;
    /* Optional: Customize the text color */
}

/* Style the prefix (e.g., RM) */
.ant-statistic-content-prefix {
    font-weight: bolder;
    /* Make the prefix bold if needed */
    color: #000;
    /* Optional: Customize prefix color */
}

/* Style the title (e.g., "Total Net Sales Today") */
.ant-statistic-title {
    font-size: 16px;
    /* Customize the font size of the title */
    font-weight: normal;
    /* Normal or lighter title */
    color: #555;
    /* Optional: Adjust color */
}

.custom-sales-card .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 120px;
}


/* CSS for the daily payment totals*/
/* General Row Styling */
.payment-row {
    margin-top: 16px;
}

/* General Card Styling */
.payment-card {
    position: relative;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 !important;
    /* Remove padding */
    height: 170px;
    background-color: #ffffff;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
}

.payment-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.payment-card-icon svg {
    min-width: 150px;
    min-height: 150px;
    font-size: 150px !important;
    /* Force override the size */
    fill: rgba(83, 196, 26, 0.15) !important;
    /* Adjust color and opacity */
    position: absolute;
    top: -20px;
    /* Adjust positioning */
    right: -20px;
    /* Adjust positioning */
    pointer-events: none;
    /* Prevent interaction with the icon */
}

/* Card Content Styling */
.payment-card-content {
    z-index: 2;
    position: relative;
}

/* Icon as Background */
.payment-card-icon {
    position: absolute;
    top: -20px;
    right: -20px;
    font-size: 150px;
    /* Adjust size as needed */
    color: rgba(82, 196, 26, 0.2);
    /* Green with reduced opacity */
    z-index: 1;
    pointer-events: none;
    /* Ensures the icon doesn't interfere with hover effects */
}

/* Title Styling */
.payment-card-title h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
}

/* Progress Bar Container */
.payment-progress-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

/* Add Shadow to Progress Bar */
.payment-progress-container .ant-progress-inner {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.363);
    /* Subtle shadow around the entire progress bar */
    border-radius: 8px;
    /* Add rounded corners */
}

.payment-progress-container .ant-progress-bg {
    box-shadow: 0px 4px 8px rgba(82, 196, 26, 0.4);
    /* Greenish shadow around the filled part */
    border-radius: 4px;
    /* Smooth rounded corners for the fill */
}

/* RM value */
.payment-item-value {
    font-weight: bold;
    font-size: 22px;
    color: #333;
}

/* Transaction Count */
.payment-transaction {
    font-size: 0.95rem;
    font-weight: 600;
    color: #333;
    /* Darker text color for contrast */
    text-align: center;
    display: inline-block;
    /* Ensures padding applies only to the text */
    width: auto;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for better readability */
}

/* Percentage label styling */
.progress-percentage {
    font-size: 0.9rem;
    color: #333;
    font-weight: bold;
    margin-left: auto;
    /* Aligns the percentage to the right */
    text-align: right;
}


/* CSS for the closing report*/
.custom-closing-card {
    border: 1px solid rgb(192, 192, 192);
    padding: 20px;
    font-size: 1rem;
    /* Base font size */
    line-height: 1.6;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.288);
}

.custom-closing-card h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
    text-transform: uppercase;
}

.custom-closing-card p {
    font-size: 1.1rem;
    color: #555;
}

.custom-closing-card strong {
    font-weight: bold;
    color: #000;
}

.ant-table-summary-row>td {
    font-weight: bold;
    font-size: 1.1rem;
    background-color: #fafafa;
}

.ant-table-summary-cell {
    text-align: center;
    padding: 12px 8px;
}

.payment-summary-table .ant-table-thead>tr>th {
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #e6f7ff;
    border-bottom: 2px solid #91d5ff;
}

.payment-summary-table .ant-table-tbody>tr>td {
    font-size: 1.1rem;
    padding: 12px;
}

.payment-summary-table .ant-table-tbody>tr:hover {
    background-color: #f5f5f5;
}

/* Transaction Record - Inputs and Dropdowns */
.custom-date-picker,
.custom-time-picker,
.custom-select {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 6px 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    background-color: #fff;
    display: flex;
    align-items: center;
}

/* Hover Effects */
.custom-date-picker:hover,
.custom-time-picker:hover,
.custom-select:hover {
    border-color: #1890ff;
    box-shadow: 0 4px 12px rgba(24, 144, 255, 0.2);
}

/* Focus Effects */
.custom-date-picker:focus-within,
.custom-time-picker:focus-within,
.custom-select:focus-within {
    outline: none;
    border-color: #40a9ff;
    box-shadow: 0 0 8px rgba(24, 144, 255, 0.4);
}

/* Input Fields */
.custom-date-picker .ant-picker-input>input,
.custom-time-picker .ant-picker-input>input {
    font-size: 14px;
    color: #333;
    flex: 1;
}

/* Suffix Icons */
.custom-date-picker .ant-picker-suffix,
.custom-time-picker .ant-picker-suffix {
    font-size: 16px;
    color: #1890ff;
    margin-left: 8px;
}

/* Arrow Icons */
.custom-date-picker .ant-picker-range-separator,
.custom-time-picker .ant-picker-range-separator{
    padding-right: 25px;
    padding-bottom: 10px;
}

.ant-picker-suffix span svg{
    margin-top: 0px !important;
}

/* Dropdown Selector */
.custom-select .ant-select-selector {
    font-size: 14px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
}

/* Dropdown Arrow */
.custom-select .ant-select-arrow {
    font-size: 16px;
    color: #1890ff;
    transition: color 0.3s ease;
}

/* Dropdown Arrow on Hover */
.custom-select:hover .ant-select-arrow {
    color: #40a9ff;
}

/* Active State */
.custom-select .ant-select-open {
    border-color: #40a9ff;
    box-shadow: 0 0 8px rgba(24, 144, 255, 0.4);
}

/* Icons Alignment Beside Inputs */
.custom-select-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* General Styling for Padding and Spacing */
.custom-select,
.custom-date-picker,
.custom-time-picker {
    font-family: 'Arial', sans-serif;
    width: 100%; /* For consistent width */
    border-radius: 8px; /* Add rounded corners */
    overflow: hidden; /* Ensures the dropdown respects the border-radius */
}

/* Dropdown with Icon Inside */
.custom-select-with-icon .ant-select-selector {
    height: 39px !important;
    display: flex;
    align-items: center;
    padding: 6px 11px; /* Space for the icon */
    border-radius: 8px !important; /* Rounded corners */
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
}

/* Ensure consistent border-radius on hover and active states */
.custom-select-with-icon:hover .ant-select-selector,
.custom-select-with-icon .ant-select-selector:focus {
    border-color: #1890ff;
    box-shadow: 0 4px 12px rgba(24, 144, 255, 0.2);
    border-radius: 8px;
}

/* Space between the icon and text */
.custom-select-with-icon .ant-select-selector > span {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
}

/* Transition for hover effect */
.custom-select-with-icon:hover {
    border-color: #1890ff;
    box-shadow: 0 4px 12px rgba(24, 144, 255, 0.2);
}

/* Dropdown Arrow Styling */
.custom-select-with-icon .ant-select-arrow {
    font-size: 16px;
    color: #1890ff;
    transition: color 0.3s ease;
}

.custom-select-with-icon:hover .ant-select-arrow {
    color: #40a9ff;
}
